import { Rate, Button, Tooltip, notification, Tag } from 'antd';
import { HeartOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Product } from 'models/product';
import { useIntl } from 'react-intl';
import './ListItemComponent.less';
import { Cart } from 'models/cart';
import { useNavigate } from 'react-router-dom';
interface IProps {
  data: Product;
}

const ListItemComponent = ({ data }: IProps): JSX.Element => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();

  const goToProductDetail = () => {
    navigate(data?.slug ? `/product/${data.type}/${encodeURIComponent(data?.slug)}` : '/products')
  };

  const onAddCart = async (cartItem: any) => {
    const cartStringData = localStorage.getItem('shoppingCart');
    let cartData: Cart = {
      total: 0,
      orderItems: [
        {
          product: cartItem,
          total: 0,
          quantity: 1,
        },
      ],
    };

    cartData = cartStringData ? JSON.parse(cartStringData) : cartData;
    const orderItem = cartData?.orderItems?.find(item => item.product?._id === cartItem._id);

    if (orderItem) {
      orderItem.total = 0;
      orderItem.quantity += 1;
    } else {
      cartData?.orderItems?.push({
        product: cartItem,
        total: 0,
        quantity: 1,
      });
    }
    localStorage.setItem('shoppingCart', JSON.stringify(cartData));
    openNotificationWithIcon('success', cartItem);
  };

  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const openNotificationWithIcon = (type: NotificationType, item: any) => {
    notification[type]({
      message: formatMessage(
        { id: 'common.event.message.success' },
        { name: formatMessage({ id: 'cart.notification.content.adding.success' }, { name: item.name }) }
      ),
      onClick: () => {
        navigate(`/cart`)
      },
    });
  };

  return (
    <div className="listItem" onClick={goToProductDetail}>
      <div className="image">
        <img
          width={272}
          alt="logo"
          src={data?.images?.[0]?.url || data?.images?.[0]?.thumbUrl || '/images/no-image.png'}
          onError={error => {
            error.currentTarget.src = '/images/no-image.png';
            error.currentTarget.onerror = null;
          }}
        />
      </div>
      <div className="content">
        <div className="leftSide">
          <div className="information">
            <div className="title">{data?.name}</div>
            <div className="description">{data?.summary}</div>
            <div className="rate">
              <Rate disabled defaultValue={4} />
            </div>
          </div>
          <div className="extraData">
          {data?.brand && <div className="brand">
              <span className='title'>{formatMessage({ id: 'product.brand' })}</span>
              <span className='value'><Tag>{data?.brand?.name}</Tag></span>
              {/* {data?.brand && `${formatMessage({ id: 'product.brand' })}:  ${data?.brand?.name}`} */}
            </div>}
            <div className="category">
              {data?.categories && <>
                <span className='title'>{formatMessage({ id: 'product.categories' })}</span>
                <span className='value'>{data?.categories
                  ?.map(item => <Tag>{item.name}</Tag>)}</span>
                
              </>}
            </div>
          </div>
        </div>
        <div className="rightSide">
          <div className="price">{formatMessage({ id: 'common.price.contactPlease' })}</div>
          <div className="action">
            {/* <Tooltip title={formatMessage({ id: 'common.button.favourite' })}>
              <Button type="link" shape="circle" icon={<HeartOutlined />} size="large" />
            </Tooltip> */}
            <Button
              type="primary"
              // icon={<ShoppingCartOutlined />}
              onClick={e => {
                e.stopPropagation();
                // onAddCart(data);
                navigate(`/product/${data.type}/${data.slug}`)
              }}
            >
              {formatMessage({ id: 'product.detail' })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItemComponent;
