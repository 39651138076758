import type { RootState } from 'config/configureStore';
import storage, { CookiesResponse } from 'utils/storage';
// import { Equipment, EquipmentKarcher, EquipmentFileView } from 'models/product';
// import { TPagination } from 'models/pagination';
import { initialState } from './slice';  

// const getStorageCurrentUser = () => {
//   storage.getCurrentUser() ? JSON.parse(storage.getCurrentUser() as string) : undefined
// }
const getCurrentUser = (state: RootState): string => state.adminAuthentications.currentUser || storage.getCurrentUser() || initialState.currentUser;
const getAccessToken = (state: RootState): any => state.adminAuthentications.accessToken || storage.getAccessToken() || initialState.accessToken;
const getClientCurrentUser = (state: RootState): string => state.adminAuthentications.clientCurrentUser || storage.getClientCurrentUser() || initialState.clientCurrentUser;
const getClientAccessToken = (state: RootState): any => state.adminAuthentications.clientAccessToken || storage.getClientAccessToken() || initialState.clientAccessToken;

const getAvatarUser = (state: RootState): string => state.adminAuthentications.avatarUser || initialState.avatarUser;
const getClientAvatarUser = (state: RootState): string => state.adminAuthentications.clientAvatarUser || initialState.clientAvatarUser;
// const getAvatarUser = (state: RootState): string => storage.getAvatarUser() || state.adminAuthentications.avatarUser;
// const getClientAvatarUser = (state: RootState): string => storage.getClientAvatarUser() || state.adminAuthentications.clientAvatarUser;

const getIsLoading = (state: RootState): Boolean => state.adminAuthentications.isLoading;


export const authenticationSelectors = {
  getCurrentUser,
  getAccessToken,
  getAvatarUser,
  getIsLoading,
  getClientCurrentUser,
  getClientAccessToken,
  getClientAvatarUser
};
