import { Button, Tag } from 'antd';
import { productsActions, productsSelectors } from 'app/containers/Product';
// import Sider from 'antd/lib/layout/Sider';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import './FilterApplied.less';

const FilterApplied = (): JSX.Element => {
  const {formatMessage} = useIntl();
  const dispatch = useDispatch();
  const productFilter = useSelector(productsSelectors.getFiltersApply);

  const onReset = useCallback(() => {
    dispatch(productsActions.setFilters(undefined));
    dispatch(productsActions.setFiltersApply(undefined));
  }, [dispatch]);

  const renderFilter = () => {
    const allFilters = [];
    if (productFilter) {
      for (const [key, value] of Object.entries(productFilter)) {
        if (value && (value as any[]).length > 0) {
          allFilters.push(
            <div className="filter">
              <div className="title">{formatMessage({ id: `filter.${key}` })}</div>
              <div className="items">
                <div>{typeof value === 'object' ? value.map(item => <Tag>{item.name || item.label}</Tag>) : value}</div>
              </div>
            </div>
          );
        }
      }
    }
    return allFilters;
  };

  const isFilters = useMemo(() => {
    let result = false;
    if (productFilter && (productFilter?.brands as any[])?.length > 0) {
      result = true;
    }
    return result;
  }, [productFilter]);

  return isFilters ? (
    <div className="filterApplied">
      <h1>
        {formatMessage({ id: 'filter.apply' })}:
        <Button type="ghost" onClick={onReset} className={'cancelButton'} >
          {formatMessage({ id: 'filter.cancel' })}
        </Button>
      </h1>
      <div className="filterBlock">{renderFilter()}</div>
    </div>
  ) : (
    <></>
  );
};

export default FilterApplied;
