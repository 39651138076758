import { Image } from "antd";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { ChangePasswordForm } from './components/ChangePasswordForm';
import './UserChangePassword.less';
import { useCallback, useMemo } from "react";
// import { authenticationHooks } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { authenticationHooks } from "app/containers/Admin/Authentication/hooks";
import { templatesHooks } from 'app/containers/Template';
import { PAGE_NAME, SETTINGS } from 'constants/common';
import { storage } from 'utils';

const UserChangePassword = (): JSX.Element => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { data: settingTemplate } = templatesHooks.useTemplates({
    search: {
      group: PAGE_NAME.P_TEMPLATE,
    },
    pagination: {
      limit: 1000,
      offset: 0,
    },
  });
  const { mutateAsync: changePassword } = authenticationHooks.useChangePassword();

  const onFinish = useCallback( async (values: any) => {
    const currentUser = storage.getClientCurrentUser();
      
      // storage.setClientAccessToken(state.accessToken);

    let user = currentUser ? JSON.parse(currentUser) : undefined
    
    if (user && user.temporaryToken) {
      // localStorage.setItem('CurrentUser', '{}')
      user = await changePassword({
        email: user.email,
        password: values.password,
        oldPassword: user.temporaryToken,
      })
      // localStorage.setItem('CurrentUser', JSON.stringify(user.data))
      // localStorage.setItem('Token', user.token as string)
      storage.setClientCurrentUser(JSON.stringify(user.data));
      
      storage.setClientAccessToken(user.token);
      navigate(`/`)
    }
  }, [changePassword, navigate])

  const logoIcon = useMemo(() => {
    if (settingTemplate) {
      return settingTemplate?.data?.find((item: any) => item.name === SETTINGS.LOGO)
    }
  }, [settingTemplate])

  return <>
    <Helmet
        title={intl.formatMessage({ id: 'page.name.changePassword' })}
      />
      <div className="userChangePassword">
        {/* <div className="bg-image">
          <img
            src={
              'https://cdn.elearningindustry.com/wp-content/uploads/2021/01/increase-sales-with-proven-elearning-elements.png'
            }
            alt={'Dai Viet'}
          />
        </div> */}
        <div className="form-container">
          <Image className='logoIcon' preview={false} src={logoIcon?.valueImages?.[0]?.url || "/images/logodv-8769.gif"} />

          <ChangePasswordForm onFinish={onFinish} />
        </div>
      </div>
  </>
}

export default UserChangePassword;

