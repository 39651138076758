export enum statusOrder {
  NEW = 'new',
  INPROGRESS = 'inprogress',
  DONE = 'done',
};

export const {
  NEW,
  INPROGRESS,
  DONE,
} = statusOrder;