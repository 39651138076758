import { createSlice } from '@reduxjs/toolkit';
import { storage } from 'utils';

export const initialState = {
  isLoading: false,
  currentUser: '',
  accessToken: '',
  avatarUser: '',
  clientCurrentUser: '',
  clientAccessToken: '',
  clientAvatarUser: '',
};

const authenticationSlice = createSlice({
  name: 'adminAuthentications',
  initialState,
  reducers: {
    setClientCurrentUser: (state, { payload }) => {
      const user = {
        ...payload,
        images: undefined,
      };

      state.clientCurrentUser = JSON.stringify(user);
      storage.setClientCurrentUser(JSON.stringify(user));

      if (user.token) {
        state.clientAccessToken = user.token;
        storage.setClientAccessToken(user.token);
      }

      if (payload.images) {
        state.clientAvatarUser = JSON.stringify(payload.images);
      }
      // storage.setClientAvatarUser(JSON.stringify(payload.images));
    },
    setCurrentUser: (state, { payload }) => {
      const user = {
        ...payload,
        images: undefined,
      };

      state.currentUser = JSON.stringify(user);
      storage.setCurrentUser(JSON.stringify(user));

      if (user.token) {
        state.accessToken = user.token;
        storage.setAccessToken(user.token);
      }

      if (payload.images) {
        state.avatarUser = JSON.stringify(payload.images);
      }
      // storage.setAvatarUser(JSON.stringify(payload.images));
    },
    setAccessToken: (state, { payload }) => {
      storage.setAccessToken(payload);
      state.accessToken = payload;
    },
    logout: state => {
      state.accessToken = '';
      state.currentUser = '';
      state.avatarUser = '';

      state.clientCurrentUser = '';
      state.clientAccessToken = '';
      state.clientAvatarUser = '';

      storage.clear();

    },
  },
});

export const { name, actions, reducer } = authenticationSlice;
