import { useQuery, UseQueryResult } from 'react-query';

import { templatesApi } from 'app/containers/Template';
import { apiErrorHandler } from 'utils';
import { ErrorResponse } from 'models/error';
import { UserDetailPayload } from 'models/user';

export const useUser = (params: UserDetailPayload): UseQueryResult<any> => {
  return useQuery(
    templatesApi.templateKeys.detail(params),
    async () => {
      const { data } = await templatesApi.getUser(params);
      return data;
    },
    {
      onError: (error: ErrorResponse) => {
        if (error?.response?.errors?.length) {
          apiErrorHandler(error?.response?.errors);
        }
      },
    }
  );
};
