import { Button, Form, Input, Select, Card, Switch } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

interface IProps {
  isUpdate?: boolean;
  onFinish?: any;
  initialValues?: any;
  isLoading?: boolean;
}

const DetailForm = ({ isUpdate, onFinish, initialValues, isLoading }: IProps): JSX.Element => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [body, setBody] = useState('');
  const { formatMessage } = useIntl();

  return (
    <>
      <Helmet title={formatMessage({ id: 'page.name.routePathDetail' })} />
      <Card
        title={formatMessage({ id: 'page.name.routePathDetail' })}
        extra={
          <Button type="ghost" htmlType="submit" onClick={() => navigate(`/admin/setting/routePath`)}>
            {formatMessage({ id: 'common.button.back' })}
          </Button>
        }
      >
        <Form
          {...formItemLayout}
          form={form}
          name="update"
          onFinish={async values =>
            await onFinish({
              ...values,
            }).then(() => navigate(`/admin/setting/routePath`))
          }
          initialValues={initialValues}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label={formatMessage({ id: 'setting.routePath.name' })}
            rules={[
              {
                required: true,
                message: formatMessage(
                  { id: 'common.validation.require.field' },
                  { name: formatMessage({ id: 'setting.routePath.name' }) }
                ),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="value"
            label={formatMessage({ id: 'setting.routePath.value' })}
            rules={[
              {
                required: true,
                message: formatMessage(
                  { id: 'common.validation.require.field' },
                  { name: formatMessage({ id: 'setting.routePath.value' }) }
                ),
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item name="isHidden" label={formatMessage({ id: 'common.showHide' })}>
            <Switch
              defaultChecked={initialValues?.isHidden}
              checkedChildren={formatMessage({ id: 'common.hide' })}
              unCheckedChildren={formatMessage({ id: 'common.show' })}
            />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {isUpdate ? formatMessage({ id: 'common.button.update' }) : formatMessage({ id: 'common.button.add' })}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default DetailForm;
