import { useTagSeos } from './useTagSeos';
import { useAdvertisements } from './useAdvertisements';
import { usePopupMenus } from './usePopupMenus';
import { useTopMenus } from './useTopMenus';
import { useTemplates } from './useTemplates';
import { useTemplate } from './useTemplate';
import { useCategories } from './useCategories';
import { useCategory } from './useCategory';
import { useProducts } from './useProducts';
import { useProduct } from './useProduct';
import { useProjects } from './useProjects';
import { useProject } from './useProject';
import { useSupports } from './useSupports';
import { useCreateContact } from './useCreateContact';
import { useSearches } from './useSearches';
import { useUser } from './useUser';

export const templatesHooks = {
  useTagSeos,
  useAdvertisements,
  usePopupMenus,
  useTopMenus,
  useTemplates,
  useTemplate,
  useCategories,
  useCategory,
  useProducts,
  useProduct,
  useProjects,
  useProject,
  useSupports,
  useCreateContact,
  useSearches,
  useUser,
};
