import { useMutation, useQueryClient } from 'react-query';

import { UserUpdatePayload } from 'models/user';
import { usersApi } from 'app/containers/Admin/User';
import { apiErrorHandler } from 'utils';
import { ErrorResponse } from 'models/error';
import { message } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { authenticationActions } from '../../Authentication';
import { RoleOptions } from 'constants/user';

export const useUpdateUser = (): any => {
  const queryClient = useQueryClient();
  const intl = useIntl();

  const dispatch = useDispatch();

  const storeLoginData = useCallback(
    ({ data }: { data: any }) => {
      dispatch(authenticationActions.setCurrentUser(data));
    },
    [dispatch]
  );

  const storeClientLoginData = useCallback(
    ({ data }: { data: any }) => {
      dispatch(authenticationActions.setClientCurrentUser(data));
    },
    [dispatch]
  );

  return useMutation(
    async (params: UserUpdatePayload) => {
      const data = await usersApi.updateUser(params);
      if (data.role === RoleOptions.CUSTOMER) {
        storeClientLoginData(data);
      } else {
        storeLoginData(data);
      }
      return data;
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(usersApi.usersKeys.lists());
        message.success(intl.formatMessage({ id: 'common.update.message.success' }));
        window.location.reload();

      },
      onError: (error: ErrorResponse) => {
        if (error?.response?.errors?.length) {
          apiErrorHandler(error?.response?.errors);
        }
      },
    }
  );
};
